<template>
  <div class="app_con">
    <app-list
      ref="applist"
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防巡检'"
      ><div class="search" slot="tool">
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">巡检日期</div>
          <el-date-picker
            style="width: 100%"
            clearable
            value-format="yyyy-MM-dd"
            v-model="searchQuery.patroDateTime"
            type="date"
            placeholder="选择巡检日期"
          >
          </el-date-picker>
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/firePatrol",
      searchQuery: { patroDateTime: "" },
      props: [
        {
          label: "巡查员",
          prop: "patrolPerson",
          align: "center",
          width: 100,
        },

        {
          label: "巡检日期",
          prop: "patroDateTime",
          align: "center",
          width: 150,
        },

        {
          label: "巡检内容",
          prop: "patrolContent",
          align: "center",
        },
        {
          label: "违章情况",
          prop: "patrolBreakCase",
          align: "center",
        },
        {
          label: "处理结果",
          prop: "patroDispositionResult",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "巡检员",
          type: "select",
          prop: "patrolPersonId",
          selectData: [],
        },

        {
          label: "巡检日期",
          type: "date",
          prop: "patroDateTime",
        },

        {
          label: "巡检内容",
          type: "select",
          prop: "patrolContentId",
          selectData: [],
          multiple:true
        },
        {
          label: "违章情况",
          type: "input",
          prop: "patrolBreakCase",
        },
        {
          label: "处理结果",
          type: "input",
          prop: "patroDispositionResult",
   
        },
      ],
    };
  },
  created() {
    this.getTypeList();
    this.getPerList();
  },
  mounted(){
    this.$nextTick(()=>{
      console.log(this.$refs.applist);
    })
  },
  methods: {
    async getTypeList() {
      await api.common.list({ apiName: "/firePatrolContent" }).then((res) => {
        let arr = res.data.data;

        arr.forEach((item) => {
          this.formProps[2].selectData.push({
            value: item.id,
            label: item.title,
          });
        });
      });
    },

    async getPerList() {
      await api.common.list({ apiName: "/meritPatrolPerson" }).then((res) => {
        let arr = res.data.data;
        console.log(arr);
        arr.forEach((item) => {
          this.formProps[0].selectData.push({
            value: item.id,
            label: item.name,
          });
        });
      });
    },

  },
};
</script>

<style lang="scss" scoped></style>
